@import url("https://getbootstrap.com/docs/5.0/dist/css/bootstrap.min.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import url('//cdn.datatables.net/1.13.4/css/jquery.dataTables.min.css');
@import url('//cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.css');

body {
  padding: 0;
    margin: 0;
    background-color: #fafdfb !important;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
}

.content {
  color: #cbcbcb;
  width: 100%;
  padding-left: 255px;
  padding-top: 56px;
  margin-bottom: 32px;
}

.loc_content{
  padding: 20px;
}

.loc_content h1{
    color: #5a5c69;
    font-size: 20px;
    padding: 25px;
    font-weight: 400;
}

.loc_content .card h2{
  color: #5a5c69;
  font-size: 18px;
  padding: 0;
  font-weight: 400;
}

.loc_content h2{
  color: #5a5c69;
  font-size: 18px;
  padding: 25px 0;
  font-weight: 400;
  margin-top: 0;
}

h2 {
  color: #000000;
  font-weight: 500;
  font-size: 20px;
  margin: 40px 40px 0 40px;
}

input[type="text"], input[type="number"]{
  font-size:13px !important;
}

select{
  background-color: white;
  border: solid 1px #eee;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), radial-gradient(#fff 70%, transparent 72%);
  background-position: calc(100% - 15px) calc(1em + 2px), calc(100% - 10px) calc(1em + 2px), calc(100% - 0.5em) 1.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  font-size:13px !important;
}

.notfound{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: 0;
  width: 70%;
}

.notfound h2{
  display: inline-block;
  font-size: 30px;
  width: 75%;
  margin-top: 0;
  border-bottom: solid 1px #dedede;
  padding-bottom:15px;
  margin-bottom:10px;
}

.notfound p{
  display: inline-block;
  font-size: 14px;
  color: rgb(51, 51, 51);
  margin-left: 40px;
  margin-top: 10px;
}

.notfound img{
  width: 15%;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
}

.notfound-text{
  display: inline-block;
  vertical-align: middle;
  width:70%;
}

.notfound .btn-goback{
  background:#00529c;
  color:#ffffff;
  border:none;
  display: inline-block;
  padding: 7px 20px;
  margin-left:40px;
  margin-top: 10px;
}

.noaccess{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: 0;
  width: 70%;
}

.noaccess h2{
  display: inline-block;
  font-size: 30px;
  width: 75%;
  margin-top: 0;
  border-bottom: solid 1px #dedede;
  padding-bottom:15px;
  margin-bottom:10px;
}

.noaccess p{
  display: inline-block;
  font-size: 14px;
  color: rgb(51, 51, 51);
  margin-left: 40px;
  margin-top: 10px;
}

.noaccess img{
  width: 15%;
  display: inline-block;
  vertical-align: middle;
}

.noaccess-text{
  display: inline-block;
  vertical-align: middle;
  width:70%;
}

.noaccess .btn-goback{
  background:#00529c;
  color:#ffffff;
  border:none;
  display: block;
  padding: 7px 20px;
  margin-left:40px;
  margin-top: 10px;
}

.goBackBtn{
  background: transparent;
  display:inline-block;
  cursor: pointer;
  border: none;
}

.goBackBtn i{
  color: #555;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, 
              rgb(0 0 0 / 14%) 0px 2px 2px 0px, 
              rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  border-radius: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.filterelem{
  display: inline-block;
}

.AlarmDetails {
    background: #ffffff;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    margin: 40px;
    padding: 20px;
}

.dashboard h1{
    color: #5a5c69;
    font-size: 20px;
    padding: 1.5rem 25px 0 25px;
    font-weight: 400;
}

.card-columns{
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
}

.card{
    display: inline-block !important;
    width: 100%;
    margin-bottom: 30px;
    border: none;
    padding: 25px 20px !important;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    border-radius: 10px;
}

.grid .card h2 {
  float: left;
  font-size: 14px;
  padding-left: 5px;
  width: 100%;
  padding-bottom: 15px;
  margin: 0px 40px 5px 0px;
  color: #757575;
  font-weight: 400;
}

.masonry .grid-item { width: 50%; }

.alarmStatus {
    float: right;
    text-align: right;
}

.actions{
    margin-top: 20px;
}

.btn-accept {
    background-color: #5cb85c;
    color: #ffffff;
    font-size: 14px;
    margin-right: 5px;
}

.btn-ignore {
    background-color: #ff0000;
    color: #ffffff;
    font-size: 14px;
    margin-right: 5px;
}

.btn-finish{
	background-color:#5cb85c;
	color:#ffffff;
    font-size: 14px;
    margin-right: 5px;
}

.btn-camera{
	background-color:rgb(51, 102, 204);
	color:#ffffff;
    font-size: 14px;
    margin-right: 5px;
}

.alarmcamera {
    display: none;
    background: #ffffff;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    margin-top: 20px;
    padding: 20px;
    text-align: center;
}

.camTitle{
    float: left;
    margin-top: -3px;
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
    width: 100%;
    border-bottom: solid 1px #e7eaed;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

/** Tabs */

#tabs{
	background:none !important;
	border:none;
	font-family: inherit !important;
}

#tabs .ui-tabs-nav{
	background:#ffffff !important;
	border-bottom: 1px solid rgba(0,0,0,.125);
    border-top: none;
    border-left: none;
    border-right: none;
}

#tabs .ui-tabs-nav li.ui-tabs-active{
	margin: 15px 0px 15px 15px !important;
	border:none !important;
	background:#00529c !important;
	border-radius: 0;
}

#tabs .ui-tabs-nav li.ui-tabs-active i{
	color:#ffffff;
	cursor: pointer;
}

#tabs .ui-tabs-nav li.ui-tabs-active a{
	color:#ffffff;
}

#tabs .ui-tabs-nav li{
	margin: 15px 0px 15px 15px !important;
	border:solid 1px #eeeeee !important;
	background:none !important;
}

#tabs .ui-tabs{
	background:transparent !important;
	border:none !important;
}

#tabs li{
    float: left;
    text-decoration: none;
    font-weight: normal;
    font-size: 13px;
}

#tabs li a{
    padding: .5em 1em;
    text-decoration: none;
}

.card-body{
    padding: 10px;
}

.contact i{
    font-size: 60px;
    float: left;
    margin-right: 20px;
}

.alarmnotactive {
    background: #999999;
}

.alarmon{
    background:#5cb85c;
}

.alarmoff{
    background:#ff0000;
}

.alarm-icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    margin: 5px 10px;
}

.btn-del-tab {
    cursor: pointer;
    color: #ff0000;
    padding: 6px;
    border-radius: 100%;
    font-size: 12px;
}

.react-tabs__tab{
    border: solid 1px #eeeeee !important;
    background: #eee !important;
    color: #333;
    font-size: 13px !important;
    margin: 0 !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.react-tabs__tab--selected {
    color: #ffffff;
    background: #0085CA !important;
    color: #fff !important;
    border-radius: 0 !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
}

h2.pending{
    position:absolute;background:#FFA500;color:#ffffff;
}

h2.new{
    position:absolute;background:#FF0000;color:#ffffff;
}

.configuremode, .configuremode.modeOff, .configuremode-btn.modeOff {
    cursor: pointer;
    color: #00529c;
    float: right;
    position: relative;
    z-index: 10;
    font-size: 15px;
    margin-right: 0px;
    border-radius: 100%;
    padding: 15px;
}

.dash_view{
    cursor: pointer;
    color: #00529c;
    float: right;
    position: relative;
    z-index: 10;
    margin-top: 13px;
    font-size: 15px;
    margin-right: 20px;
    border-radius: 100%;
    padding: 15px;
    width: 45px;
    text-align: center;
}

.dash_view:hover{
    background: #f5f5f5;
}

.dash_view_options{
    width: 200px;
    position: absolute;
    right: 10px;
    background: #ffffff;
    top: 110px;
    min-height: 50px;
    z-index: 999999;
    border: solid 1px #eee;
}

.dash_view_options ul{
    margin:0;
    padding:0;
}

.dash_view_options ul li{
    list-style: none;
    color: #000;
    padding: 10px;
    border-bottom: solid 1px #eee;
    width: 100%;
    text-align: left;
    font-size: 12px;
}

.dash_view_options ul li.current{
    color: #00529c;
    font-weight: bold;
}

.configuremode.modeOn, .configuremode-btn.modeOn {
    cursor: pointer;
    background: #00529c;
    color: #ffffff;
    float: right;
    position: relative;
    z-index: 10;
    margin-top: 13px;
    font-size: 15px;
    border-radius: 100%;
    padding: 15px;
}

  .addCardType {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 20px;
    background: #00529c;
    border-radius: 100%;
    color: #ffffff;
    padding: 14px 16px;
    z-index: 10;
}

.tabs li i, .tabs .card h2 i{
    display:none;
}

.showControls li i, .showControls .card h2 i{
    display:inline-block;
}

.showControls li{
    padding:0 !important;
}

.react-tabs__tab-list{
    border-bottom: none !important;  
    margin: 20px 25px !important;
    padding: 0 !important;
}

.checkboxoption {
    color: #333333;
    border: solid 1px #dddddd;
    padding: 15px;
    margin-bottom: 10px;
}

.checkboxoption table td {
    width: 200px;
}

table{
    width:100%;
}

table td {
    color: #000000;
    font-size: 14px;
    height: 45px;
    font-weight: 300;
}

table td input[type="checkbox"]{
    margin-right:5px;
}

.card .alarms_filters{
    margin-top: 3px;
    position: absolute;
    right: 10px;
    font-size: 13px;
}

.card .alarms_filters .btn-alarms-filter{
    font-size:13px;
}

.card .alarms_filters .current{
    color: #00529c !important;
    font-weight: bold;
}

.card #alarms7days{
    display:none;
}

.form-group{
    margin-bottom:20px;
}

.contact{
    color: #333333;
    padding: 20px 10px;
}

.contact i{
    background: -webkit-linear-gradient(45deg, #00529c, #5f9ad0 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* .legend-day, .legend-week, .legend-month, .legend-year{
    display: none;
    position: absolute;
    background: #ffffff;
    width: 96.6%;
    margin-top: 30px;
    z-index: 10;
    overflow-y: scroll;
    height: 300px;
} */

.loadingScreen{
    position: fixed;
    z-index: 999999;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 1);
  }
  
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #00529c;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 40%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 45%;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
  }

  .dash_view_options span{
    background: #f9f9f9;
    color: #333;
    font-weight: 600;
    display:block;
    padding: 10px;
  }

  .tipsblock{
    cursor: pointer;
  }

  .tipsblock-front, .tipsblock-back{
    display:none;
  }

  .tipsblock-front h3{
    text-align: center;
    color: #212529;
    padding: 60px 0 0 0;
    font-size: 22px;
  }

  .tipsblock-back h3{
    text-align: center;
    color: #212529;
    padding: 60px 0 60px 0;
    font-size: 20px;
  }

  .tipsblock-front span{
    display: block;
    width:100%;
    text-align: center;
    color: #000000;
    padding: 20px 0 50px 0;
    font-size: 14px;
  }

  .tipsblock-front span i{
    color: #00529c;
    margin-right: 10px;
  }

  .tipsblock-front .front-left-top{
    background: #00529c;
    position: absolute;
    top:0;
    left: 0;
    width:150px;
    height: 50px;
  }

  .tipsblock-front .front-left-bottom{
    background: #00529c;
    position: absolute;
    left: 0;
    bottom: 0;
    width:150px;
    height: 50px;
  }

  .tipsblock-front .front-right-top{
    background: #FFA500;
    position: absolute;
    top:0;
    right: 0;
    width:150px;
    height: 50px;
  }

  .tipsblock-front .front-right-bottom{
    background: #FFA500;
    position: absolute;
    bottom:0;
    right: 0;
    width:150px;
    height: 50px;
  }

  .tipsblock-back .top{
    background: #00529c;
    height: 20px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .tipsblock-back .right{
    background: #00529c;
    height: 100%;
    width: 20px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .tipsblock-back .left{
    background: #00529c;
    height: 100%;
    width: 20px;
    position: absolute;
    left: 0;
    top: 0;
  }

  .tipsblock-back .bottom{
    background: #00529c;
    height: 20px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .slider-wrapper {
    margin: 1rem;
    position: relative;
    overflow: hidden;
  }
  
  .slides-container {
    height: calc(100vh - 2rem);
    width: 100%;
    display: flex;
    overflow: scroll;
    scroll-behavior: smooth;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .slide-arrow {
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 4rem;
    background-color: white;
    border: none;
    width: 2rem;
    font-size: 3rem;
    padding: 0;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 100ms;
  }
  
  .slide-arrow:hover,
  .slide-arrow:focus {
    opacity: 1;
  }
  
  #slide-arrow-prev {
    left: 0;
    padding-left: 0.25rem;
    border-radius: 0 2rem 2rem 0;
  }
  
  #slide-arrow-next {
    right: 0;
    padding-left: 0.75rem;
    border-radius: 2rem 0 0 2rem;
  }
  
  .slide {
    width: 100%;
    height: 100%;
    flex: 1 0 100%;
  }

  .DataTable_Container label, .dataTables_info, .dt-paging .dt-paging-button{
    color: #222 !important;
    font-size: 13px;
    font-weight: 300;
    border: none;
    margin-right: 18px;
    padding: 5px 10px;
  }

  .dashboard-editor{
    height: 100vh;
    display: flex;
    flex-direction: row;
    background: #fafdfb;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
}

.dashboard-editor .tileCatalogue{
    width: 250px;
    border-right: solid 1px #e4ecfb;
    position: relative;
}

.dashboard-editor ul{
    padding:0;
    border-bottom: none !important;
}

.dashboard-editor ul li{
    list-style: none;
    cursor: pointer;
    position: relative;
}

.dashboard-editor .tileCatalogue ul li:hover{
    background:#dae4f6;
}

.dashboard-editor .tileCatalogue ul li{
    padding: 15px 20px;
    color:#333;
}

.dashboard-editor ul li i{
    font-size: 20px;
    vertical-align: sub;
    color: #0085CA;
    
    margin-right:10px;
}

.dashboard-editor .content{
    flex: 1 1 auto;
    overflow: auto;
    background-color: #f7faff;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
}

.dashboard-editor .modal .modal-content{
    background-color: #ffffff;
    color:#333;
}

.dashboard-editor .modal .modal-content p{
    margin-top:20px;
}

.dashboard-editor .modal .modal-header{
    background: #0085CA;
    color: #fff;
}

/* .dashboard-editor .modal .modal-footer{
    padding-bottom: 20px !important;
} */

.dashboard-editor .modal .form-group{
    margin:0;
    margin-bottom:10px;
}

.dashboard-editor .modal .form-group label{
    margin-bottom:5px;
}

.dashboard-editor .modal .btn-add{
    float:none;
    height:auto;
    display:block;
    margin:auto;
}

.dashboard-editor .react-tabs .btn-del-tab{
    font-size: 12px !important;
    vertical-align: middle !important;
    background:#ff0000 !important;
    color: #ffffff !important;
    margin: 0 !important;
    cursor: pointer;
    position: absolute;
    top: -10px;
    right:-10px;
}

.dashboard-editor .modal .modal-body input {
    margin-bottom:20px;
}

.dashboard-editor .modal#add-tab .modal-body {
    padding: 15px 25px 7px 25px;
}

.dashboard-editor .card h2{
    margin: 0px 0px 0 0px;
    font-size: 16px;
}

.dashboard-editor .container-fluid{
    padding: 0;
}

.dashboard-editor .empty-arrow{
    width: 20%;
    transform: rotate(-30deg);
    margin-top: -40px;
    margin-left: -50px;
}

.dashboard-editor .empty-title{
    margin: 0px;
    margin-left: 150px;
    margin-top: -50px;
    font-weight: 400;
}

.dashboard-editor .empty-subtitle{
    margin: 0px;
    margin-left: 150px;
    margin-top: 10px;
    font-size:16px;
}

.dashboard-editor .saveDash{
    position: absolute;
    bottom:0;
    width: 100%;
    background:#dae4f6;
    font-size: 14px;
    text-align: center;
}

.dashboard-editor .modal .modal-footer{
    padding:0;
}

.contact-icon{
    background: #0085CA;
    color: #ffffff;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    margin-right: 15px;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
}

.bulk{
    display:none;
}

.contact-team{
    display:inline-block;
    background:#f5f5f5;
    margin-right:5px;
    padding: 7px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
}

.filters{
    padding: 20px 40px 15px 30px;
    color: #333333;
    min-height: 70px;
    background: #ffffff;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    margin-top: 30px;
    border-radius: 10px;
    margin: 0px 25px 30px 25px;
    font-size:13px;
    font-weight: 300;
}

.filters select{
/* styling */
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), radial-gradient(#fff 70%, transparent 72%);
  background-position: calc(100% - 15px) calc(1em + 4px), calc(100% - 10px) calc(1em + 4px), calc(100% - 0.5em) 1.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}

.filters input, .filters select{
    padding: 0;
    width: auto !important;
    min-width: 160px;
    border-bottom: none;
    padding: 5px !important;
    margin-left: 10px;
    border: 1px solid #eee !important;
    padding: 10px 10px !important;
    margin-right:20px;
    font-size: 12px;
    border-radius: 10px;
}

.chart{
    background: #ffffff;
    padding: 25px;
    margin: 0 25px 30px 25px;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}

#NewAlarmsDay{
    display:none;
}

.alarmDetails {
    background: #ffffff;
    font-size: 13px;
    line-height: 30px;
    color: #333333;
    margin-top: 20px;
    padding: 20px 40px;
}

.alarmDetails table{
    width:100%;
}

.alarmDetails table tr td{
    font-size:13px;
}

.performanceTotal{
    margin: 0 35px;
}

.performanceBlock{
    background: #ffffff;
    color: #333333;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    padding:20px;
    margin-right: 25px;
    text-align: center;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    border-radius: 10px;
    max-width: 350px;
}

.performanceBlock i{
    font-size: 25px;
    float: left;
    margin-right: 20px;
    background: #0085CA;
    color: #ffffff;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    padding: 5px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 32px;
}

.performanceBlock .block_title{
    font-weight: 500;
    font-size: 14px;
}

.performanceBlock .block_value{
    font-weight: 600;
    font-size: 14px;
    color:#0085CA;
}

.search-btn{
    background: #0085CA;
    color: #ffffff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    text-align: center;
    display: inline-block;
    padding: 9px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.ui-datepicker .ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
    border: solid 1px #0085CA !important;
    background: #0085CA !important;
    color: #ffffff !important;
}

.ui-datepicker .ui-widget-header {
    border: 1px solid #fff !important;
    background: #ffffff !important;
    color: #333 !important;
    font-weight: 100 !important;
}

.ui-datepicker .ui-datepicker th{
    font-weight: 100 !important;
}

.ui-datepicker .ui-datepicker td, .ui-datepicker td a{
    text-align: center !important;
}

.react-datepicker-popper{
    z-index:999999 !important;
}

/* .alarmDetails table tr td:first-child{
    font-weight: bold;
} */

.no-check{
    color: #ffffff;
    background: #ff0000;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 26px !important;
}

.check{
    color: #ffffff;
    background: green;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 26px !important;
}

progress[value] {
    -webkit-appearance: none;
    appearance: none;
    width: 65%;
    height: 20px;
    margin-right: 15px;
    float: left;
    margin-top: 5px;
  }

progress[value]::-webkit-progress-bar {
    background-color: #f0f0f0;
    height: 10px;
    border-radius: 10px;
  }

.batGreen[value]::-webkit-progress-value {
    background: green;
    border-radius: 10px; 
    height: 10px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

.batOrange[value]::-webkit-progress-value {
    background: orange;
    border-radius: 10px; 
    height: 10px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

.batRed[value]::-webkit-progress-value {
    background: red;
    border-radius: 10px; 
    height: 10px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

.heartbeatmap-overlay{
    display:none;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height:100%;
    z-index: 999998;
}

.closeMap{
    position: absolute;
    z-index: 999999;
    right: 10px;
    top: 8px;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
}

.heartbeatmap{
    display:none;
    width: 500px;
    height: 400px;
    background: rgb(255, 255, 255);
    position: fixed;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 999998;
}

.heartbeatmap h3{
    font-size: 14px;
    background: #0085CA;
    margin: 0;
    color: #ffffff;
    padding: 10px 15px;
}

.status-accepted{
    display: inline-block;
    text-align: center;
    vertical-align: top;
}

.status-accepted span{
    display: block;
    margin-top: 15px;
    width:200px;
    color: #777777;
}

.status-finished{
    display: inline-block;
    text-align: center;
    vertical-align: top;
}

.status-finished span{
    display: block;
    width: 100px;
    margin-top: 15px;
    width:200px;
    color: #777777;
}

.status-accepted i.fa-check-circle{
    color:#009f07;
    font-size: 30px;
}

.status-accepted i.fa-times-circle{
    color:#ff0000;
    font-size: 30px;
}

.status-finished i{
    font-size: 30px;
}

.status-teams{
    display: inline-flex;
    vertical-align: top;
}

.status-teams .badge-pill{
    font-size: 14px;
    padding: 5px 0px;
    color: #777777 !important;
}

.status-teams i{
    font-size:30px;
    padding-bottom:10px;
    display: block;
    margin: 0px 20px;
}

.status-teams .teamReceived{
    color:#009f07;
}

.status-teams .teamNotReceived{
    color:#c7cad9;
}

.border-divider{
    display: inline-block;
    border-top:solid 1px #c7cad9;
    height:1px;
    width:100px;
    margin:0 20px 0 20px;
}

.status{
    margin: 0px 40px 40px 40px;
    text-align: center;
    display: inherit;
    background: #ffffff;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    border-radius: 10px;
    padding: 35px 0;
}

.status-info{
    display: block;
    min-width: 600px;
    margin-top: 50px;
}

.showAcceptedName{
    display:block;
}

.hideAcceptedName{
    display:none;
}

.alarm-route{
    text-align: center;
    background: rgba(199, 202, 217, 0.1);
    padding: 30px 100px;
    border: solid 1px rgb(199, 202, 217);
    width: fit-content;
    margin: auto;
    position: relative;
}

.alarm-route .closeroute{
    position: absolute;
    right: -7px;
    top: -7px;
    color: #777777;
    font-size: 20px;
    cursor: pointer;
}

.status h2{
    text-align: center;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 30px;
}

.alarm-route h2{
    text-align: center;
    margin-top: 0px;
    margin-bottom:20px;
}

.routeteam{
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #777777;
}

.routeteam .team{
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    position: relative;
}

.routeteam .team i{
    display: block;
    font-size: 25px;
    margin-bottom: 10px;
}

.routeteam .team .teamsequence{
    display: block;
    font-size: 12px;
}

.routeteam .sequence-arrow{
    display: inline-block;
    padding: 0 20px;
    font-size: 20px;
}

.status-teams .team{
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.status-team .sequence-arrow{
    display: inline-block;
    font-size: 20px;
    color:#777777;
}

.status-team .sequence-arrow i{
    font-size: 20px;
}

.routeteam:last-child .sequence-arrow{
    display:none !important;
}

.status-team:last-child .sequence-arrow{
    display:none !important;
}

.route-info{
    color: #72a7cf;
    cursor: pointer;
}

.team-users{
    background: #f1f3f7;
    padding: 20px 20px;
    border: solid 1px rgb(199, 202, 217);
    color:#777777;
    text-align: left;
    position: absolute;
    margin-bottom: 20px;
    display:none; 
}

.team-users div{
    margin-bottom: 10px;
}

.help{
    background: #eee;
    border: solid 1px #ccc;
    padding: 10px 20px;
    margin: 20px 2.5rem -0 2.5rem;
    color:#333;
}

.dt-button{
	padding: 10px 15px !important;
}

table.dataTable.nowrap th{
    padding: 20px 20px;
    border-bottom: 1px solid #efefef;
    font-weight: 600;
    color: #555;
}

table.dataTable.nowrap thead tr{
    background: #ffffff;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody{
    border-bottom: none;
}

.react-datepicker-wrapper, .react-datepicker__tab-loop{
    display: inline-block !important;
    width: auto !important;
}

.chart-view{
    font-weight: 100;
    color: #333;
    padding: 30px 30px 30px 30px !important;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    margin: 0 25px;
}

.ALARM_IN_BED_DETECTION{
    background: #d0b2cf !important;
}

.ALARM_OUT_OF_BED_DETECTION{
    background: #b66f2b !important;
}

.ALARM_OUT_OF_ROOM_DETECTION{
    background: #003C72 !important;
}

.ALARM_SOEB_DETECTION{
    background: #ecdb0e !important;
}

.ALARM_MAN_DOWN_DETECTION{
    background: #a01407 !important;
}

.ALARM_MISSING_BED_DETECTION{
    background: #dc3545 !important;
}

.ALARM_PERSON_VISIBLE_DETECTION{
    background: #25b100 !important;
}

.ALARM_IN_BATHROOM_DETECTION{
    background: #01ace1 !important;
}

.ALARM_NO_DETECTION{
    background: #808080 !important;
}

.ALARM_STAFF_ENTERING_ROOM_DETECTION{
    background: #000000 !important;
}

.alarm-no-data{
    background: #808080 !important;
}

.sg-task-content{
    display: none !important;
}

.sg-table-body-cell{
    font-weight: 400 !important;
    color:#000;
}

.sg-table-header-cell.svelte-87uanl{
    font-weight: 600 !important;
}

.sg-timeline-body{
    overflow: hidden !important;
}

.legend{
    text-align: center;
    padding: 15px 0;
    font-size: 14px;
    color:#333;
}

.legend-item{
    display: inline-block;
    margin-right:20px; 
}

.legend-block{
    width:10px;
    height: 10px;
    display: inline-block;
    margin-right:5px;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
}

.legend-day span{
    width:10px !important;
    height: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
}

.read-only{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10 !important;
}

.employee .contact-icon {
    background: #0085CA;
    color: #ffffff;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    margin-right: 15px;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.employee span{
    font-size: 20px;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
}

.employee .currentTeams{
    margin: 5px 55px;
}

.employee .currentPhone{
    margin: -5px 55px 15px 55px;
}

.status_icon{
    float: left;
    font-size: 25px;
    margin-top: 15px;
    margin-right: 30px;
    color: #0085CA;
}

.status_title{
    font-size: 16px;
    font-weight: 600;
}

.streamtitle{
    font-size: 15px;
    border-bottom: solid 1px #ddd;
    padding-bottom: 5px;
    margin-bottom: 20px;
    font-weight: 500;
}

.select-queues-groups{
    width: 100%;
    position: absolute;
    z-index: 11;
}

.select-queues-groups .list-queue-groups {
    background: #ffffff;
    /* width: 100%;
    max-width: 480px;
    position: absolute;
    z-index: 999999;
    left: 0;
    right: 0;
    top: 0;
    padding: 15px;
    margin-left: auto;
    margin-right: auto; */
    padding:30px;
    margin: 0 60px 0 30px;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    -webkit-box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    /* border: solid 1px #b7bcd1;
    -webkit-transform: translateY(50%);
    transform: translateY(50%); */
}
  
  .select-queues-groups h1{
    text-align: center;
    margin-bottom: 20px;
  }
  
  .select-queues-groups table{
    width:100%;
  }

  .select-queues-groups button{
    display: block;
    margin: auto;
    margin-top: 20px;
  }

  .select-queues-groups tbody tr, .select-queues-groups tbody tr td{
    border:none;
  }

  .select-queues-groups tbody tr:nth-child(even){
    background: #f8f8f8;
  }

  strong.call{
    color: #333;
    font-size: 14px;
    font-weight: 500;
  }

  .alarm-employees, .alarm-logs, .add_note{
    background: rgb(255, 255, 255);
    border-radius: 10px;
    margin: 20px 40px;
    padding: 30px;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
  }

  .add_note form{
    padding:0;
    border-bottom: none;
  }

  .alarm-status{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
  }

  .alarmnotes{
    margin: 20px 40px;
  }

  .battery {
    padding: 3px;
    width: 20%;
    border: solid thin #000;
    position: relative;
    border-radius: 5px;
    box-shadow: 5px 5px 5px #ccc;
  }
  
  .battery:after {
    content: " ";
    top: 5px;
    right: -7px;
    height: 10px;
    width: 7px;
    position: absolute;
    background: #000;
  }

  .battery .bar {
    cursor: pointer;
    display: inline-block;
    width: 0;
    border: solid thin rgba(255, 255, 255, .1);
    padding: 14px;
    height: 30px;
    background: transparent;
    transition: background 1s;
  }

  .battery progress[value] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 15px;
    margin-right: 15px;
    float: none;
    margin-top: 5px;
  }

  .battery.batGreen .progress-bar{
    background: green;
  }

  .battery.batGreen:after{
    background: green;
  }

  .battery.batOrange .progress-bar{
    background: orange;
  }

  .battery.batOrange:after{
    background: orange;
  }

  .battery.batRed .progress-bar{
    background: red;
  }

  .battery.batRed:after{
    background: red;
  }

  .roominsights .filters .btn-blue{
    margin: 0 !important;
    float: right;
  }

  .dataTable-top td{
    vertical-align: top;
}

.dataTable-top td{
    padding-top:15px !important;
}

.DataTable_Container {
    margin: 30px 2.5rem 0px 2.5rem;
}

#DataTable{
    margin-bottom:0;
}

#DataTable thead th{
    padding: 20px 20px 20px 10px;
    border-bottom: 1px solid #efefef;
    font-weight: 600;
    color:#000000;
}

#DataTable tr{
    background:#ffffff;
    cursor: pointer;
}

#DataTable tr:first-child td{
    border-top:none;
}

#DataTable td{
    padding:15px;
}

#DataTable{
    margin-bottom:0;
}

#DataTable .openAlarm .pictogram {
    /* display: inline-block;
    padding: 0 0 0 0;
    margin: 10px 10px 10px 10px;
    background: rgb(255,0,0); */
    display: inline-block;
    margin: 10px 15px 10px 10px;
    background: #0085CA;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 10px;
    padding: 5px;
}

#DataTable tbody td:first-child{
    padding: 0rem;
}


#DataTable td{
    padding:10px;
    color: #000000;
    font-size: 13px;
    height: 45px;
    font-weight: 300;
    vertical-align: middle;
    border-top: 1px solid #efefef;
    border-bottom: none;
}

#DataTable .openAlarm td{
    padding:10px;
    color: #000000;
    font-size: 13px;
    height: 45px;
    font-weight: 300;
    vertical-align: middle;
    border-top: 1px solid #efefef;
}

#DataTable .openAlarm:first-child td{
    border-top:none;
}

.DataTable_Container {
    background: #ffffff;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    border-radius: 10px;
    padding: 30px;
    margin: 30px 25px;
}

.DataTable_Container thead{
    display:none;
}


.DataTable_Container table{
    margin-bottom:0;
}

.DataTable_Container h2 {
    float: left;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
    width: 100%;
    border-bottom: solid 1px #e7eaed;
    padding-bottom: 15px;
    margin-bottom: 0;
}

.DataTable_Container .historyAlarm .pictogram {
    /* display: inline-block;
    padding: 0 0 0 0;
    margin: 10px 10px 10px 10px;
    background: rgb(255,0,0); */
    display: inline-block;
    margin: 10px 15px 10px 10px;
    background: #0085CA;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 10px;
    padding: 5px;
}

.DataTable_Container .table tbody td:first-child{
    padding: 0rem;
}

.DataTable_Container .historyAlarm td{
    padding:10px;
    color: #000000;
    font-size: 14px;
    height: 45px;
    font-weight: 100;
    vertical-align: middle;
    border-top: 1px solid #efefef;
}

.DataTable_Container .historyAlarm:first-child td{
    border-top:none;
}

.DataTable_Container .dataTables_length{
    padding: 0 0 30px 0;
}

.DataTable_Container .dataTables_length select{
    border: 1px solid #ced4da !important;
    padding: 5px 5px !important;
    margin-right: 5px !important;
    background:#ffffff;
    border-radius: 10px;
}

.DataTable_Container .dt-search{
    padding: 0 0 10px 0;
    margin-top:20px;
}

.DataTable_Container .dt-search input{
    border: 1px solid #ced4da !important;
    padding: 10px 10px !important;
    margin-right: 5px !important;
    background:#ffffff;
    border-radius: 10px;
    width:93%;
}

.DataTable_Container table.dataTable.no-footer {
    border-bottom: none;
}

.DataTable_Container .dt-info{
    padding: 30px 0 0 0;
    color: #000000;
}

.DataTable_Container .dt-paging{
    padding: 30px 0 0 0;
    float: right;
}

.DataTable_Container .dt-paging-button.current, .dt-container .dt-paging .dt-paging-button.current, .dt-container .dt-paging .dt-paging-button.current:hover {
    background: none !important;
    background-color: #00529c !important;
    border-color: #00529c !important;
    color: #fff !important;
}

.showHead thead{
    display: table-header-group;
}

.badge-info{
    margin-right:20px;
}

.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 20px;
  }
  
  .switch input { 
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    /* top: 0;
    left: 0; */
    right: 0;
    bottom: 0;
    background-color: #ccc;
    box-sizing: border-box;
    width: 32px;
    height: 14px;
    border-radius: 7px;
    transition: opacity 90ms cubic-bezier(0.4, 0, 0.2, 1) 0s, background-color 90ms cubic-bezier(0.4, 0, 0.2, 1) 0s, border-color 90ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    transition: .4s;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border: 10px solid;
    pointer-events: none;
    z-index: 1;
    background-color: #ffffff;
    border-color: #ffffff;
    left: -7px;
    top: -3px;
  }
  
  input:checked + .slider {
	background-color: #0085CA;
  }
  
  input:focus + .slider {
	box-shadow: 0 0 1px #0085CA;
  }
  
  input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
  }
  
  .slider.round {
	border-radius: 34px;
  }
  
  .slider.round:before {
	border-radius: 50%;
  }

  .btn-delete{
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    line-height: 30px !important;
    text-align: center;
    color: #ffffff;
    background: #ff0000;
    font-size: 12px;
  }

  .btn-confirm{
    background: orange;
    border:none;
    color: #ffffff;
    padding: 7px 20px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 20px;
  }

  .btn-edit{
    background: orange;
    color: #ffffff;
    padding: 7px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .btn-add, .btn-save{
    background-color: #5cb85c;
    color: #ffffff;
    border: none;
    padding: 7px 20px;
    float: right;
    margin-right: 40px;
    margin-top: 15px !important;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 20px;
    line-height: 25px;
    font-weight: 300;
  }

  .btn-blue{
    background-color: #0085CA;
    color: #ffffff;
    border: none;
    padding: 7px 20px;
    float: right;
    margin-left:-20px;
    margin-right: 40px;
    margin-top: 15px !important;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 20px;
    line-height: 25px;
    font-weight: 300;
  }

  .btn-save{
    background-color: #5cb85c;
    color: #ffffff;
    border: none;
    padding: 7px 20px;
    float: right;
    margin-right: 40px;
    margin-top: 35px;
    font-size: 14px;
  }

  .modulesTable table{
    margin: 20px 0 0 0 !important;
  }

  .orgDetails, .deviceDetails, .modulesTable table{
    background: #ffffff;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    margin-top: 20px;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    border-radius: 10px;
    padding: 30px;
    margin: 20px;
  }

  .deviceDetails{
    background: #ffffff;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    margin-top: 20px;
    padding: 20px;
    margin: 20px 40px 0 40px;
  }

  .teamDetails{
    background: #ffffff;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    margin-top: 20px;
    padding: 20px;
    margin: 20px 40px 0 40px;
  }

  .routeDetails{
    background: #ffffff;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    margin-top: 20px;
    padding: 20px;
    margin: 20px 40px 0 40px;
  }

  #newItem{
    background-color: #5cb85c;
    color: #ffffff;
    border: none;
    padding: 7px 20px;
    float: right;
    margin-right: 40px;
    margin-top: 35px;
    font-size: 14px;
  }

  .orgDetails .table{
    margin:0;
  }

  .table th{
    border-bottom: 1px solid #efefef;
    font-weight: 600;
    color: #555;
    border-top:none;
  }

  .table tr td{
    font-size: 13px;
    font-weight: 400;
    padding: 10px 10px;
    border-top: none;
  }

  .table tr td select{
    font-size: 13px;
  }

  .table tr td input{
    font-size: 13px;
  }

  .destination{
    font-size:13px;
  }

  .destination select{
    font-size:13px;
  }

  .destination input{
    margin-right:5px;
  }

  .modal{
    display: none;
    position: fixed;
    z-index: 999999;
    padding-top: 100px;
    right: 0;
    top: 0;
    background-color: rgba(0,0,0,0.4);
    opacity:1 !important;
  }

  .modal label {
    font-size: 13px;
    letter-spacing: 0px;
    font-weight: 400;
    color: #333333;
}
  
  .modal .modal-header{
    background: #2196F3;
    color: #ffffff;
    border-radius: 0;
    padding: 15px 20px;
  }
  
  .modal .modal-header h4{
    font-size: 13px;
    font-weight: 300;
  }
  
  .modal .modal-header .close{
    color: #2196F3;
    cursor: pointer;
    font-size: 20px;
    float: right;
    background: #ffffff;
    border-radius: 100%;
    width: 21px;
    height: 21px;
    text-align: center;
    padding: 0;
    line-height: 22px;
    font-weight: 300;
  }
  
  .modal .modal-body{
    padding: 15px 15px 7px 15px;
  }
  
  .modal .modal-content{
    background-color: #fefefe;
      margin: auto;
      padding: 0;
      border: 1px solid #888;
      width: 600px;
  }
  
  .modal .modal-content form{
    margin:0 !important;
    padding: 0 15px;
  }
  
  .modal .modal-content form label{
    font-size: 13px;
    font-weight: 400;
  }
  
  .modal .modal-content form .form-control{
    font-size: 14px;
  }

.modal-footer{
	display: block !important;
	padding-bottom: 0 !important;
  padding-top: 0 !important;
  border-top:none;
}

.modal-footer .save{
	width:100%;
	height:100%;
	background-color: #5cb85c;
	border:none;
	color:#ffffff;
	border-radius:0
}

.modal-footer .cancel{
	width:100%;
	background:#ff0000;
	color:#ffffff;
	border-radius:0
}

.modal-footer .cancel, .modal-footer .save{
	cursor: pointer;
	font-size: 14px;
}

.modal .btn-green{
	background-color: #5cb85c;
  color: #ffffff;
  width: 100%;
  border-radius: 0;
  font-size: 13px;
  font-weight: 300;
}

.btn-green{
	background-color: #5cb85c;
  margin: 15px 0;
  color: #ffffff;
  width: auto;
  border-radius: 0;
  font-size: 14px;
}

.modal .btn-red{
	margin: 15px 0;
  width: 100%;
  background: #ff0000;
  border-radius: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 300;
}

.modal .form-control{
	font-size:14px !important;
}

.modal form{
  border-bottom: none;;
}

.btn-reset-dashboard {
  background: #FF0000;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 10px;
  float: right;
  margin-top: 19px;
  color: #ffffff;
}

.notice-dashboard-reset {
  background: #ffffff;
  padding: 20px;
  text-align: center;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 1px 1px 10px rgba(0,0,0,.125);
  color: #333333;
  font-size: 14px;
  display: none;
}

.alarmDetails input[type="text"], .alarmDetails input[type="number"], .alarmDetails select{
  height:40px;
}

#btnYes{
  color: #ffffff;
  border: none;
  font-size: 14px;
  margin:0;
}

.modalTitle{
  font-size:14px;
  color:#333;
  margin-bottom:25px;
}

#btnNo{
  background: #ff0000;
  color: #ffffff;
  border: none;
  font-size: 14px;
  width: 100%;
  border-radius: 0;
  margin:0;
}

#modal_dialog .modal-body{
  padding: 0px 12px;
}

#modal_dialog .modal-body .row{
  padding: 0 15px 15px 15px;
}

#modal_dialog i{
    font-size: 50px;
    color: orange;
    margin-bottom: 15px;
    margin-top:25px;
}

.btnimage{
  display: inline-block;
  padding: 0 !important;
  margin-right: 5px;
}

#GetIconPicker{
  display: inline-block;
    background: rgb(0, 82, 156);
    color: #ffffff;
    border: none;
    padding: 5px 15px;
}

.upload label{
  display: block;
  font-weight: 500;
}

.upload .uploadBtn::-webkit-file-upload-button {
  visibility: hidden;
}

.upload .uploadBtn{
  display:flex;
}

.upload form{
  border-bottom:none;
  padding-bottom: 0 !important;
}

.upload .uploadBtn::before{
    content: 'Bestand selecteren';
    display: inline-block;
    border: solid 1px #ddd;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-align: center;
    font-size: 13px;
}

.upload input[type="submit"]{
  background: green;
    color: #ffffff;
    border: none;
    padding: 7px 15px;
}

.nightnurse_devices{
  margin: 20px 2.5rem 0px 2.5rem;
}

.nightnurse_devices .device{
  background: #ffffff;
  padding: 10px;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  vertical-align: middle;
  padding-left: 20px !important;
  border-top: 1px solid #efefef;
  border-bottom: none;
}

.nightnurse_devices .device span.device_name{
  font-weight: 500;
  background: #f1f1f1;
  width: 100%;
  display: block;
  padding: 10px;
  margin: 10px 0;
}

.accordion {
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.accordion:after {
  content: '\02795'; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
}

.accordion.active:after {
  content: "\2796"; /* Unicode character for "minus" sign (-) */
}

.active, .accordion:hover {
  background-color: #eee;
}

.panel {
  display:none;
  padding: 0 18px;
  background-color: white;
  transition: max-height 0.2s ease-out;
}

.alert-success, .alert-danger{
  display:none;
  margin: 30px 40px 30px 40px;
}

.nightnurse_devices table tr{
  border-bottom: solid 1px #eee;
}

.nightnurse_devices table tr td{
  padding: 10px 0;
}

.msg_no_location{
  display:none;
}

.btn-truncate{
  background:#FF0000;
  color: #ffffff;
  font-size: 13px;
  float: right;
  margin-right: 40px;
  margin-top: 40px;
}

.noStatus{
  color: #ff0000;
  font-size: 100px;
  width: 100%;
  text-align: center;
}

.btn-backtodash{
  float: none;
  margin: 0px 0px 0px;
  font-size: 12px;
  border-bottom: solid 1px #ddd;
  width: 100%;
  padding: 15px;
  text-align: left;
}

.btn-backtodash:hover{
  border-bottom: solid 1px #ddd;
}

.uploadfield{
    padding: 0px 20px;
    margin: 20px;
}

.uploadfield input{
    border: 1px solid #ced4da !important;
    padding: 5px !important;
    background: #ffffff;
}

.current-video{
    border: 1px solid #ced4da !important;
    padding: 20px !important;
    background: #ffffff;
    margin: 20px;
    color:#000;
    font-size: 13px;
}

.uploadfield .btn-upload-video{
    background-color: #00529c;
    color: #ffffff;
    font-size: 13px;
    border-radius: 0;
}

#accordion .card{
    background:transparent !important;
    border:none;
}

#accordion .card-header{
    background:transparent !important;
    border-bottom:none;
}

.card-header, .card-body{
    padding:0;
}

.settingsTable{
    margin: 0px 20px;
    background: rgb(255, 255, 255);
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    padding: 20px 30px;
    border-radius: 10px;
}

.settingsTable table tr td{
    padding: 15px 20px;
}

.settingsTable table tr{
    border-bottom:solid 1px #ededed;
}

.settingsTable table tr td i{
    position: absolute;
    right: 90px;
    margin-top: -23px;
}

/* .settingsTable select{
    border: solid 1px #eee;
    font-size: 13px;
} */

.settingsTable select{
    /* styling */
      background-color: white;
      border: solid 1px #eee;
      border-radius: 4px;
      display: inline-block;
      font: inherit;
      line-height: 1.5em;
      padding: 0.5em 3.5em 0.5em 1em;
    
      /* reset */
    
      margin: 0;      
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
    
      background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), radial-gradient(#fff 70%, transparent 72%);
      background-position: calc(100% - 15px) calc(1em + 2px), calc(100% - 10px) calc(1em + 2px), calc(100% - 0.5em) 1.5em;
      background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
      background-repeat: no-repeat;
    }

#accordion .card h2{
    margin: 40px 65px 0;
}

.btnSave{
    position: absolute;
    right: 20px;
    margin-top: -22px;
}

.modulesTable{
    margin: 0px 25px 0px 25px;
}

.modulesTable table thead{
    display:none;
}

.modulesTable table tr{
    background: #ffffff;
}

.modulesTable table tr td{
    border-bottom: 1px solid #efefef;
    padding: 20px;
}

.modulesTable .modulesCategory{
    font-size: 15px;
    margin-top: 20px;
    font-weight: 300;
    color: #777777;
    margin-bottom: 10px;
}

.content{
    margin-bottom: 0 !important;
}

.device-info{
    position: absolute;
    right: 0px;
    padding: 15px;
    box-shadow: rgb(183 188 209) 6px 16px 31px -18px;
    width: 400px;
    height: 100vh;
    background: rgb(255, 255, 255);
    top: 57px;
}

.trackTrace{
    width: calc(100% - 400px);
    height: calc(100vh - 77px);
}

.filters i{
    font-size:16px;
    color:#00529c;
}

.gm-style .place-card, .gm-style .default-card, .gm-style .directions-card{
    display:none !important;
}

.locations{
    height:calc(100vh - 80px);
    overflow-y: scroll;
}

.device-loc-historie{
    overflow: hidden;
}

.device-loc-historie .loc-day{
    float: right;
    color: #333;
    font-weight: 600;
    font-size: 13px;
}

.device-loc-historie .loc-day i{
    color: #00529c;
    margin-right:5px;
}

.device-loc-historie h3{
    color: #333;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid rgb(221, 221, 221);
    padding-bottom: 10px;
    margin-bottom: 20px;
    
}

.loc-block{
    position: relative;
}

.loc-block .block-border{
    width: 1px;
    height: 100%;
    position: absolute;
    background: #ddd;
    left: 11px;
    z-index: 0;
    top: 10px;

}

.loc-block .loc-date{
    display: inline-block;
    color: #333;
    box-shadow: #ddd 0px 1px 3px 0px;
    border-radius: 10px;
    padding: 5px 10px 5px 25px;
    background: #fff;
    margin-left: 0px;
    position: relative;
    z-index: 0;
}

.loc-block .loc-city{
    display: inline-block;
    color: #333;
    font-weight: bold;
    margin-left: 15px;
    font-size: 13px;
}

.loc-block .loc-label{
    color: #333;
    display: inline-block;
    width: 100%;
    padding: 0 35px;
    margin: 20px 0 20px 0;
}

.loc-block .loc-address{
    color: #333;
    display: inline-block;
    width: 100%;
    padding: 0 35px;
    margin: 5px 0 20px 0;
}

.device-data .loc-icon{
    margin-top: 5px;
    padding-bottom: 5px;
    margin-left: 6px;
    margin-top: 1px;
    color:#333;
    font-weight: bold;
}

.loc-block .loc-icon{
    position: absolute;
    top: 5px;
    z-index: 1;
    padding-bottom: 5px;
    margin-left: 6px;
    margin-top: 1px;
}

.loc-block .loc-icon i, .device-data .loc-icon i{
    color: #00529c;
    font-size: 16px;
    margin-top:1px;
}

.card.batteryStatus h3{
    text-align: center;
    margin-bottom: 15px;
}

.card.batteryStatus h3 i{
    color: #00529c;
    display: inline-block;
    vertical-align: middle;
}

.card.batteryStatus h3 span{
    display: inline-block;
    font-size: 16px;
    vertical-align: middle;
    color: #333;
}

.card.batteryStatus .progress{
    background: #f8f8fa;
    height: 40px;
    border: solid 2px #efefef;
    padding: 5px;
    border-radius: 0;
    position: relative;
    overflow: visible;
}

.card.batteryStatus .progress .progress-bar{
    /* background-color: #00529c; */
    position: relative;
    overflow: visible;
}

.card.batteryStatus .progress.batGreen .progress-bar {
    background-color: green;
}

.card.batteryStatus .progress.batOrange .progress-bar, .border-top.batOrange {
    background-color: orange;
}

.card.batteryStatus .progress.batRed .progress-bar, .border-top.batRed {
    background-color: red;
}

.batMsg{
    display:none;
}

.batMsg.batOrange{
    background: rgba(255, 165, 0, 0.1);
    display:block;
}

.batMsg.batRed{
    background: rgba(255, 0, 0, 0.1);
    display:block;
}

.location-history-header{
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    margin: 0px 40px 15px 40px;
}

.currentTemp{
    font-size: 20px;
    color: #333;
    width: 100%;
    text-align: center;
    display: block;
    font-weight: 700;
}

.header{
	background-color: #0085CA;
    /* background-image: linear-gradient(to right, #00529c , #003C72); */
    padding: 0.75em 1.5em 0.75em 1.5em;
	color:#ffffff;
    position: fixed;
    width:100%;
    z-index: 999999;
}

.header .logo{
    width: 7%;
    float: left;
    margin-top: 3px;
}

.header .version{
    margin-top: 10px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    font-size: 13px;
    font-weight: 300;
}

.header .header-icon{
    cursor: pointer;
    float: right;
    font-size: 20px;
    padding: 4px 10px 5px 0;
    margin-right: 5px;
    color: #ffffff;
    border-radius: 100%;
    width: 40px;
    text-align: center;
    position: relative;
}

.header .header-icon i{
    margin-left:10px;
    font-size: 17px;
}

.header .header-icon.logout-icon{
    margin-right: 0px;
}

.header .header-badge {
    float: right;
    color: #ffffff;
    position: absolute;
    top: 0px;
    font-size: 11px;
    right: 0px;
    font-weight: 500;
    width: 17px;
    height: 17px;
    text-align: center;
    padding: 0;
    line-height: 17px;
}

.header .header-badge.bg-info{
    background-color: #01ace1 !important;
}

.header .user-name {
    float: right;
    margin-top: 10px;
    margin-left: 5px;
    border-left: solid 1px rgba(255,255,255,0.3);
    padding-left: 15px;
}

/*
.header .alarmNotices{
    cursor: pointer;
    float: right;
    font-size: 20px;
    padding: 4px 10px 5px 0;
    margin-right: 20px;
    color: #ffffff;
    background: rgba(255,255,255,0.1);
    border-radius: 100%;
    width: 40px;
    text-align: center;
    position: relative;
}

.header .alarmNotices i{
    margin-left:10px;
    font-size: 17px;
}

.header .teamsSignUp{
    cursor: pointer;
    float: right;
    font-size: 20px;
    padding: 4px 10px 5px 0;
    margin-right: 20px;
    color: #ffffff;
    background: rgba(255,255,255,0.1);
    border-radius: 100%;
    width: 40px;
    text-align: center;
    position: relative;
}

.header .teamsSignUp i{
    margin-left:10px;
    font-size: 17px;
}

.header .user-icon{
    cursor: pointer;
    float: right;
    font-size: 20px;
    padding: 4px 10px 5px 0;
    margin-right: 10px;
    color: #ffffff;
    background: rgba(255,255,255,0.1);
    border-radius: 100%;
    width: 40px;
    text-align: center;
    position: relative;
}

.header .user-icon i{
    margin-left:10px;
    font-size: 17px;
}

.header .logout-icon{
    cursor: pointer;
    float: right;
    font-size: 20px;
    padding: 4px 10px 5px 0;
    color: #ffffff;
    background: rgba(255,255,255,0.1);
    border-radius: 100%;
    width: 40px;
    text-align: center;
    position: relative;
}

.header .logout-icon i{
    margin-left:10px;
    font-size: 17px;
} */

.header .nrOfTeams{
    background: #01ace1;
    font-size: 11px;
    padding: 1px 4px;
    color: #ffffff;
    position: absolute;
    right: -7px;
    bottom: 0px;
}

.header .nrOfAlarms {
    background: #ff0000;
    font-size: 11px;
    padding: 1px 4px;
    color: #ffffff;
    position: absolute;
    right: -7px;
    bottom: 0px;
}

.teamsList{
	position: absolute;
    background: #ffffff;
    top: -9px;
    border: solid 1px #eee;
    padding: 20px 25px;
	height: 101vh;
	border-top: none;
	right: -300px;
	border-right: none;
	width:270px;
	box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    transition: all 0.5s ease-out;
}

.teamsList.slide-in {
    right: 0;
}

.teamsList_title{
	color:#333333;
	border-bottom: solid 1px #eee;
	padding-bottom: 15px;
	margin-bottom: 0px;
	margin-top: 10px;
}

.teamsList table{
	margin-top:0;
}

.teamsList table tr{
	border-bottom:solid 1px #eee;
}

.teamsList table td{
	height: 0px;
    padding: 15px;
}

.closeTeams {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px;
}

.closeTeams i {
    font-size: 15px;
    color: #333;
}

.latest-alarms{
    width: 270px;
    min-height: 100px;
    position: absolute;
    background: #ffffff;
    top: -9px;
    border: solid 1px #eee;
    height: 101vh;
    border-top: none;
    right: -300px;
    border-right: none;
    padding: 20px 0;
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    transition: all 0.5s ease-out;
}

.latest-alarms.slide-in {
    right: 0;
}

.latest-alarms .alarm {
    padding: 11.5px 15px 11.5px 15px;
}

.latest-alarms a {
    color: #333333;
    text-decoration: none;
}

.latestAlarms_title {
    padding-left: 25px;
    color: #333333;
    border-bottom: solid 1px #eee;
    padding-bottom: 15px;
    margin-bottom: 0px;
    margin-top: 10px;
}

.closeLatestAlarms {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px;
}

.closeLatestAlarms i {
    font-size: 15px;
    color: #333;
}

.btnSidebar{
    font-size: 20px;
    margin-top: 12px;
    display:none;
}

.alarms-viewMore{
    border-top: solid 1px #ccc;
    width: 100%;
    display: block;
    text-align: center;
    position: absolute;
    bottom: 0px;
    padding: 15px 0;
}

.openalarms_alert{
    display:none;
    position: fixed;
    right: 0px;
    z-index: 999999;
    bottom: 0px;
    background: #ff0000;
    padding: 10.5px 20px;
    width: 250px;
    color: #ffffff;
}

.openalarms_alert i{
    font-size: 20px;
    float: left;
    margin-top: 9px;
    margin-right: 15px;
}

.user-name i{
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.mobile_menu{
    display:none;
  }

  .sidebar {
    position: fixed;
    background-color: #ffffff;
    width: 255px;
    height: 100%;
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    overflow-y: scroll;
    z-index: 999998;
    transition: width 0.25s;
    -ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: thin;  /* Firefox */
    padding-top: 56px;
}

.sidebar .angle{
    float:right;position:relative;
}

.sidebar::-webkit-scrollbar {
    display: none;
}

.sidebar .logo{
    display: none !important;
	color: #ffffff;
    font-size: 35px;
    display: block;
    text-align: center;
    padding: 20px 0 0 0;
}

.sidebar .sidebar_menu{
	padding:0;
	margin:0;
    height: 100%;
}

.sidebar .sidebar_menu li{
	list-style-type: none;
	color:#555;
	padding: 15px 0px;
	cursor: pointer;
}

.sidebar .sidebar_menu li.submenu-item{
	color: #000000;
    font-size: 13px;
    font-weight: 300;
    padding-left: 15px;
    padding-right: 15px;
}

.sidebar .sidebar_menu li i{
	position: relative;
    font-size: 13px;
    line-height: 1;
    margin-right: 1.125rem;
}

.sidebar .sidebar_menu li i.submenu{
	margin-top:5px;
	margin-right:0;
}

.sidebar .sidebar_menu li i.icon{
	color:#0085CA !important;
    width:10px;
}

.sidebar .menu-item-label {
    padding: 0 15px 10px 15px;
    font-size: 12px;
    font-weight: 400;
    color: #757575;
}
.sidebar .submenu-item:hover, .sidebar .active .submenu-item{
    background: #eee;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.sidebar a{
    text-decoration: none;
}

.sidebar .menu-item-label-name {
    display: inline-block;
}

.sidebar .menu-collapse ul {
    padding: 0 !important;
    padding-left: 10px;
}

/* .sidebar .menu-collapse {
    display: none;
}

.sidebar .show {
    display: block !important;
} */

.sidebar .version{
    color: #aaa;
    font-size: 11px;
    margin-top: 10px;
}
.sidebar .sidebar_menu li i.icon
.closeSidebar{
    float:right;
    display: none !important;
    font-size: 20px;
    margin-right: 15px;
    margin-top: 15px;
}

.select_location{
    margin: 20px 0;
}

.select_location #locations{
    width:220px;
    display: block;
    margin: auto;
    border-radius: 10px;
}

.close-sidebar{
    display: none;
}

.center{
    font-family: 'Open Sans', sans-serif;
    background-image: linear-gradient(90deg,#00529c,#003c72);
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    left:0;
    top:0;
    z-index: 999999;
}

.login{
    background: #ffffff;
    width: 400px;
    padding: 30px;
    box-shadow: 0 0 25px rgba(0,0,0,0.07);
    position: absolute;
    left: 0;
    margin: auto;
    right: 0;
}

.card > form{
    display: flex;
    flex-direction: column;
}

.login form{
    border-bottom: solid 1px #ddd;
    padding-bottom: 20px;
}

.card h1{
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
}

.login select{
    width: 83%;
    margin-left:10px;
}

.login select, .login input[type="text"], .login input[type="password"] {
    border-radius: 0px;
    padding: 8px 10px;
    width: 100%;
    border: 1px solid #ced4da;
    font-size: 13px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    margin-bottom: 1rem;
    font-family: 'Open Sans', sans-serif;
}

.form-submit{
    background-color: #00529c;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    letter-spacing: 1px;
    width: 100%;
}
.form-submit:hover{
   opacity: 0.6;
}

.login_logo{
    margin: auto;
    display: block;
    width: 400px;
    margin-top: 100px;
    background: #ffffff;
    padding: 30px 70px 10px 70px;
    position: relative;
    z-index: 10;
}

.verification{
    display:none;
    background:#ffffff;
    width: 400px;
    padding: 65px 30px;
    box-shadow: 0 0 25px rgba(0,0,0,0.07);
    position: absolute;
    left: 20%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.verification h2{
    margin: 0 0 20px 0;
}

.verification canvas{
    margin: auto;
    display: block;
}

.verification .verify{
    background-color: #00529c;
    color: #ffffff;
    border: none;
    padding: 5px 20px;
    font-size: 14px;
}

.verification p{
    font-size:14px;
    margin-bottom:30px;
}

.btn-azure{
    background-color: #00529c;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    display: block;
    margin: auto;
    margin-top: 20px;
    width:100%;
    cursor: pointer;
}

.btn-azure i{
    margin-right: 5px;
    color: #00adef;
    font-size: 15px;
}

.login span{
    width: 100%;
    text-align: center;
    display: block;
    margin-top: 20px;
    color: #aaa;
}

.err_msg, .msg{
    display:none;
    padding: 10px 0;
    color: #ff0000;
    width: 100%;
    text-align: center;
    background: rgba(255,0,0,0.2);
    border: solid 1px #ff0000;
    margin-top: 20px;
}

.api-status{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #FF0000;
    position: absolute;
    bottom: -12px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border: solid 5px #ffffff;
}

span.mbaspan{
    color: #333;
    font-weight: 600;
    font-size: 15px;
    margin: 20px 40px;
    display: inline-block;
    width: 100%;
}

.mbamenu{
    text-align: center;
    background: #ffffff;
    padding: 5px;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}

.mbamenu a{
    list-style-type: none;
    display:inline-block;
    background:#ffffff;
    padding:10px 10px;
    margin:5px;
    color:#333333;
    text-decoration: none;
}

.mbamenu a.active{
    background: #f6f7fb;
}

.mba-btn{
    float: right;
    font-weight: bold;
    background: transparent;
    color: #ffffff;
    border: none;
    margin-top: 10px;
    margin: 10px;
    text-decoration: none;
}

.mba-btn.active{
    background:transparent;
}

.disabledText{
    color: #CCC !important;
}

.italic{
    font-style: italic;
}

.actions i{
    margin: 0 5px;
    font-size:20px;
}

.settings_overview{
    margin: 30px 40px;
}

.settings_overview ul{
    padding:0;
    margin:0;
}

.settings_overview h3{
    color:#333333;
    font-size:16px;
    margin:20px 0;
}

.settings_overview ul li{
    list-style-type: none;
    display: inline-block;
    border:solid 1px #dddddd;
    background:#ffffff;
    padding:20px 20px;
    width:32.7%;
    margin:5px;
    color:#333333;
    border-radius: 10px;
    cursor: pointer;
    font-size: 13px;
}

.settings_overview ul li i{
    margin-right:10px;
    color:#0085CA;
    font-size:16px;
}

.alarmCall{
    display: inline-block;
    background: rgb(238, 238, 238);
    padding: 7px 20px;
    border-radius: 5px;
}

@media screen and (min-width:769px) and (max-width:1024px){
    .login {
        position: absolute;
        transform: translate(0%, -50%);
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .content{
        padding-left:0;
    }

    .btnSidebar{
        display:block;
    }

    .sidebar{
        display:none;
    }

    .closeSidebar{
        display:block !important;
    }
}

  @media screen and (max-width:768px){
    .sidebar{
        left:-300px;
        z-index: 999999;
        padding-top:0;
        transition: all 0.5s ease-out;
    }

    .sidebar.slide-in{
        left:0;
    }

    .sidebar .logo{
        display: inline-block !important;
        width:55%;
        display: inline-block;
        margin-left:20px;
    }
  
    .close-sidebar{
      display: block;
      border-bottom: solid 1px #ddd;
      padding-bottom:15px;
      width: 100%;
    }
  
  .close-sidebar i{
      float:right;
      cursor: pointer;
      font-size: 20px;
      margin-right: 14px;
      margin-top: -27px;
  }
  
    .header .logo, .header .version, .header .user-name{
        display: none !important;
    }

    .mobile_menu{
        display:block;
      }
      
      .mobile_menu i{
        font-size: 20px;
        float: left;
        margin-top:10px;
        cursor: pointer;
      }

    .performanceBlock{
        max-width:100%;
    }

    .battery{
        width:70%;
    }

    .export_to_file{
      display: none;
    }

    .status-info{
      margin:20px;
    }

    .border-divider{
      width:20px !important;
      margin: 0 10px !important;
    }

    .content {
        padding-left: 0px;
      }
  }